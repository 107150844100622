import { useState, useRef, useContext, useEffect } from "react";
import { Modal, Form, ProgressBar, Spinner } from "react-bootstrap";
import Button from "../button-template/ButtonTemplate";
import { ImageUploader, DeleteImage } from "../../operations/ImageHandler";
import { ExifExtractor } from "../../operations/ExifExtractor";
import { getCoordinates } from "../../operations/GetCoordinates";
import { addTree } from "../../operations/AddTree";
import { AuthContext, UserContext } from "../../pages/Dashboard";
import "./TreeForm.css";
import { db } from "../../configs/Firebase";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import ButtonTemplate from "../button-template/ButtonTemplate";
import WalletButton from "../wallet-button/WalletButton";

export const TreeForm = ({ formName, buttonName, UUID, type, currentTree }) => {
  const { sub } = useContext(AuthContext);
  const currentUser = useContext(UserContext);

  const [selected, setSelected] = useState("");
  const [treeSpecies, setTreeSpecies] = useState({});
  const [error, setError] = useState("");
  const [treeSubmitted, setTreeSubmitted] = useState(false);

  // Modal Show/Hide
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    // if (!treeSubmitted) {
    //   DeleteImage(UUID);
    // }
  };
  const handleShow = () => setShow(true);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [account, setAccount] = useState(null);
  const [currentCords, setCurrentCords] = useState("");

  const getTreeSpecies = async () => {
    const docRef = doc(db, "treeData", "species");
    const docSnap = await getDoc(docRef);
    // set the docs mapped as an array
    setTreeSpecies(docSnap.data());
  };

  useEffect(() => {
    getTreeSpecies();
  }, []);

  /**
   * Function that will set different values to state variable
   * based on which dropdown is selected
   */
  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };

  // Tree Information
  const treeRef = {
    walletAddress: useRef(""),
    treeName: useRef(""),
    species: useRef(""),
    maturity: useRef(""),
    height: useRef(""),
    heightUnit: useRef(""),
    diameter: useRef(""),
    diameterUnit: useRef(""),
    coordinates: useRef(""),
    health: useRef(""),
    openSeaLink: useRef("")
  };

  /**
   *
   */
  const handleSubmit = async () => {
    let canContinue = true;

    Object.keys(treeRef).forEach((key) => {
      if (treeRef[key].current.value == "") {
        setError("Missing Data - Each Field Is Mandatory!");
        canContinue = false;
      } else if (key == "coordinates") {
        const pattern =
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g;
        if (!pattern.test(treeRef[key].current.value)) {
          setError("Incorrect Coordinates.");
          canContinue = false;
        }
      }
    });

    if (canContinue) {
      setTreeSubmitted(true);
      setError("");
      if (type == "edit") {
        const currentUserDoc = doc(db, "users", sub);
        let storedTrees = {
          nftreeInfo: [],
        };

        for (let i = 0; i < currentUser.totalTrees; i++) {
          storedTrees.nftreeInfo.push(currentUser.nftreeInfo[i]);
          if (currentUser.nftreeInfo[i].id === currentTree.id) {
            storedTrees.nftreeInfo[i].name = treeRef["treeName"].current.value;
            storedTrees.nftreeInfo[i].maturity =
              treeRef["maturity"].current.value;
            storedTrees.nftreeInfo[i].height =
              treeRef["height"].current.value +
              treeRef["heightUnit"].current.value;
            storedTrees.nftreeInfo[i].diameter =
              treeRef["diameter"].current.value +
              treeRef["diameterUnit"].current.value;
            storedTrees.nftreeInfo[i].coordinates =
              treeRef["coordinates"].current.value;
            storedTrees.nftreeInfo[i].health = treeRef["health"].current.value;
            storedTrees.nftreeInfo[i].openSeaLink = treeRef["openSeaLink"].current.value;
            storedTrees.nftreeInfo[i].isVerified = "unknown";
          }
        }
        await setDoc(currentUserDoc, storedTrees, { merge: true });
      } else {
        addTree(sub, UUID, treeRef);
      }
      handleClose();
      setUploadProgress(0);
    }
  };

  return (
    <>
      <div className="tree-button-wrapper">
        <ButtonTemplate onClick={handleShow}>{buttonName}</ButtonTemplate>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            {/* IMAGE UPLOAD */}
            <div>
              <label className="upload-image">
                <input
                  type="file"
                  className="upload-input"
                  onChange={() => {
                    ExifExtractor(setCurrentCords)
                    ImageUploader(UUID, setUploadProgress)
                  }}
                />

                {uploadProgress === 0 && "Upload Tree Image"}
                {uploadProgress === 100 && "Upload Successful ✓"}
                {uploadProgress > 0 && uploadProgress < 100 ? (
                  <>
                    {" "}
                    Uploading...
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="tree-verify-spinner"
                    />
                  </>
                ) : (
                  ""
                )}
              </label>
              {uploadProgress !== 100 ? (
                <ProgressBar
                  animated
                  variant="warning"
                  className="progressBar"
                  now={uploadProgress}
                />
              ) : (
                <ProgressBar
                  variant="success"
                  className="progressBar"
                  now={100}
                />
              )}
            </div>

            {/* METAMASK WALLET */}
            {!currentTree && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Wallet Address (ERC-20):</Form.Label>
                <div className="metamask-form">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Enter Wallet Address"
                    defaultValue={account}
                    ref={treeRef["walletAddress"]}
                  />
                  <WalletButton setState={setAccount}/>
                </div>
                <span className="underMessage">
                  This will replace the address in your profile.
                </span>
              </Form.Group>
            )}

            {/* TREE NAME */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Tree Name:</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter Name"
                defaultValue={currentTree ? currentTree.name : ""}
                ref={treeRef["treeName"]}
              />
            </Form.Group>

            {/* TREE SPECIES */}
            {!currentTree && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Species:</Form.Label>
                <Form.Select size="sm" onChange={changeSelectOptionHandler}>
                  {Object.keys(treeSpecies).map((key) => (
                    <option key={key}>{key}</option>
                  ))}
                </Form.Select>
                <Form.Select size="sm" ref={treeRef["species"]}>
                  {treeSpecies[selected] &&
                    treeSpecies[selected].map((key) => (
                      <option key={key}>{key}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            )}

            {/* TREE MATURITY */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Maturity:</Form.Label>
              <Form.Select
                size="sm"
                ref={treeRef["maturity"]}
                defaultValue={currentTree ? currentTree.maturity : ""}
              >
                <option value="Seed">Seed</option>
                <option value="Sprout">Sprout</option>
                <option value="Sapling">Sapling</option>
                <option value="Tree">Tree</option>
              </Form.Select>
            </Form.Group>

            {/* TREE HEIGHT */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Height:</Form.Label>
              <div className="form-unit">
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Enter Height"
                  ref={treeRef["height"]}
                  min="0"
                  defaultValue={
                    currentTree
                      ? currentTree.height.replace(/[^0-9.]/g, "")
                      : ""
                  }
                />
                <Form.Select
                  size="sm"
                  className="unit-dropdown"
                  ref={treeRef["heightUnit"]}
                  defaultValue={
                    currentTree
                      ? currentTree.height.replace(/[^A-Za-z]/g, "")
                      : ""
                  }
                >
                  <option value="ft">Feet</option>
                  <option value="in">Inches</option>
                  <option value="m">Meters</option>
                  <option value="cm">Centimeters</option>
                </Form.Select>
              </div>
            </Form.Group>

            {/* TREE DIAMETER */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Diameter:</Form.Label>
              <div className="form-unit">
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Enter Diameter"
                  ref={treeRef["diameter"]}
                  min="0"
                  defaultValue={
                    currentTree
                      ? currentTree.diameter.replace(/[^0-9.]/g, "")
                      : ""
                  }
                />
                <Form.Select
                  size="sm"
                  className="unit-dropdown"
                  ref={treeRef["diameterUnit"]}
                  defaultValue={
                    currentTree
                      ? currentTree.diameter.replace(/[^A-Za-z]/g, "")
                      : ""
                  }
                >
                  <option value="ft">Feet</option>
                  <option value="in">Inches</option>
                  <option value="m">Meters</option>
                  <option value="cm">Centimeters</option>
                </Form.Select>
              </div>
            </Form.Group>

            {/* USER COORDINATES */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="cords-form-div">
                <Form.Label>Coordinates:</Form.Label>
                {/* <Button className='locate-button' onClick={() => getCoordinates(setCurrentCords)}>
                                    <i><u>Locate Me</u></i>
                                </Button> */}
              </div>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Example: 23.45673, -28.92836"
                defaultValue={currentTree ? currentTree.coordinates : currentCords}
                ref={treeRef["coordinates"]}
              />
              <span className="underMessage">
                Latitude, Longitude - Must be minimum 5 decimal places.
              </span>
            </Form.Group>

            {/* OpenSea Link */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>OpenSea Link:</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter link here"
                defaultValue={currentTree ? currentTree.openSeaLink : ""}
                ref={treeRef["openSeaLink"]}
              />
              <span className="underMessage">
                Enter link to OpenSea NFT here.
              </span>
            </Form.Group>

            {/* TREE HEALTH */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Health:</Form.Label>
              <Form.Select
                size="sm"
                ref={treeRef["health"]}
                defaultValue={currentTree ? currentTree.health : ""}
              >
                <option value="Healthy">Healthy</option>
                <option value="Average">Average</option>
                <option value="Weak">Weak</option>
              </Form.Select>
            </Form.Group>

          </Form>
        </Modal.Body>

        <Modal.Footer>
          <p className="fieldError">{error}</p>
          <Button
            className="tree-button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
