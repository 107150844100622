import React, { useState } from "react";
import { Form, Container, Row, Col, Alert } from "react-bootstrap";
import ButtonTemplate from "../components/button-template/ButtonTemplate";
import "./GenerateCode.css";
import { codeGenerator } from "../operations/CodeGenerator";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const GenerateCode = () => {
	const [species, setSpecies] = useState("");
	const [maturity, setMaturity] = useState("");
	const [height, setHeight] = useState("");
	const [health, setHealth] = useState("");
	const [generatedCode, setGeneratedCode] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	const { user } = useAuth0();
	const changePage = useNavigate();

	/**
     * Check if the current user is anything other than the users below otherwise 
     * they get redirected to the dashboard.
     * 
     * If the user is not logged in they will be redirected to the login screen.
     */
    if (user.email != "gtazia123@gmail.com" && user.email != "alex@cascadiacarbon.com") {
        changePage('/dashboard');
    }

	const generateHandler = async (e) => {
		e.preventDefault();

		// Validate the form fields
		if (!species || !maturity || !height || !health) {
			setShowAlert(true);
			return;
		} else {
			setShowAlert(false);
		}

		try {
			const treeInformation = {
				generatedOn: new Date(),
				claimed: false,
				species: species,
				maturity: maturity,
				height: height,
				health: health,
				imported: false
			};
			const generatedCode = await codeGenerator(treeInformation);
			setGeneratedCode(generatedCode);
		} catch (error) {
			console.error("Error generating code:", error);
		}
	};

	return (
		<Container className="generate-section">
			<Row>
				<Col>
					<Form>
						<h3>Code Generator</h3>
						<Form.Group controlId="species">
							<Form.Label>Tree Species</Form.Label>
							<Form.Control type="text" placeholder="Enter tree species" value={species} onChange={(e) => setSpecies(e.target.value)} />
						</Form.Group>
						<Form.Group controlId="maturity">
							<Form.Label>Tree Maturity</Form.Label>
							<Form.Control type="text" placeholder="Enter tree maturity" value={maturity} onChange={(e) => setMaturity(e.target.value)} />
						</Form.Group>
						<Form.Group controlId="height">
							<Form.Label>Tree Height</Form.Label>
							<Form.Control type="text" placeholder="Enter tree height" value={height} onChange={(e) => setHeight(e.target.value)} />
						</Form.Group>
						<Form.Group controlId="health">
							<Form.Label>Tree Health</Form.Label>
							<Form.Control type="text" placeholder="Enter tree health" value={health} onChange={(e) => setHealth(e.target.value)} />
						</Form.Group>
                        {showAlert && (
							<Alert variant="danger" className="mt-3">
								Please fill out all fields before generating the code.
							</Alert>
						)}
						<div className="generate-submit">
							<ButtonTemplate onClick={generateHandler}>Generate Code</ButtonTemplate>
						</div>
					</Form>
					{generatedCode && (
						<div className="mt-4">
							<h3>Generated Code: {generatedCode}</h3>
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default GenerateCode;
