import React from "react";
import { Navbar, Nav, Container, Offcanvas, Dropdown } from "react-bootstrap";
import Button from "../button-template/ButtonTemplate";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "../../assets/CC.png";
import "./Navigation.css";
import { Link } from "react-router-dom";

const Navigation = () => {
	const { loginWithRedirect, isAuthenticated, user, logout } = useAuth0();

	const LoginButton = (props) => (
		<Button onClick={() => loginWithRedirect()} {...props}>
			LOG IN
		</Button>
	);

	const LogoutButton = (props) => (
		<Button onClick={() => logout({ returnTo: window.location.origin })} {...props}>
			LOG OUT
		</Button>
	);

	const NavItemsAndRoutes = (props) => (
		<>
			<Nav className={`me-auto nav-links ${props.className}`}>
				<Nav.Link as={Link} eventKey="0" to="/" className="nav-link">
					Home
				</Nav.Link>
				<Nav.Link as={Link} eventKey="1" to="/dashboard" className="nav-link">
					Dashboard
				</Nav.Link>
				{isAuthenticated && (user.email === "gtazia123@gmail.com" || user.email === "alex@cascadiacarbon.com") ? (
					<>
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle as={Nav.Link} className="nav-link">
								Admin
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									<Nav.Link as={Link} eventKey="2" to="/admin" className="nav-link">
										Dashboard
									</Nav.Link>
								</Dropdown.Item>
								<Dropdown.Item>
									<Nav.Link as={Link} eventKey="2" to="/generate/code" className="nav-link">
									Generate Code
									</Nav.Link>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</>
				) : null}
			</Nav>

			{isAuthenticated ? <LogoutButton {...props} /> : <LoginButton {...props} />}
		</>
	);

	return (
		<Navbar expand="md" variant="light" className="nav-block" collapseOnSelect="true">
			<Container className="nav-container" fluid>
				<Navbar.Brand href="/">
					<img alt="" src={Logo} width="100" height="50" className="d-inline-block align-top" />
				</Navbar.Brand>

				<Navbar.Toggle className="on-canvas" />

				<Navbar.Offcanvas placement="end">
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>Carbon Codex</Offcanvas.Title>
					</Offcanvas.Header>

					<Offcanvas.Body>
						<NavItemsAndRoutes />
					</Offcanvas.Body>
				</Navbar.Offcanvas>

				<NavItemsAndRoutes className="off-canvas" />
			</Container>
		</Navbar>
	);
};

export default Navigation;
