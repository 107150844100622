import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import './ClaimTree.css';
import ButtonTemplate from '../components/button-template/ButtonTemplate';
import { db } from '../configs/Firebase';
import { getDoc, doc, updateDoc, arrayUnion } from "@firebase/firestore";

const ClaimTree = () => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [showCodeAlert, setShowCodeAlert] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the entered code
    if (!/^[A-Za-z0-9]{5}$/.test(code)) {
      setShowCodeAlert(true);
      setShowEmailAlert(false);
      setClaimSuccess('');
      return;
    }

    // Validate the entered email
    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email)) {
      setShowEmailAlert(true);
      setShowCodeAlert(false);
      setClaimSuccess('');
      return;
    }

    // Attempt to claim the code
    try {
      const docRef = code === "CODEX" ? doc(db, "emailCollection", "CoP28") : doc(db, "treeCodes", code);
      const docSnap = await getDoc(docRef);
      const docData = code === "CODEX" ? {
        arrayList: arrayUnion(email)
      } : {
        claimedOn: new Date(),
        claimed: true,
        email: email
      };

      if (docSnap.exists()) {
        const data = docSnap.data();

        if (!data.claimed) {
          // Update the claimed attribute to true and store the email
          await updateDoc(docRef, docData);
          setClaimSuccess(true);
        } else {
          setClaimSuccess(false);
        }
      } else {
        setClaimSuccess(false);
      }
    } catch (error) {
      console.error('Error claiming code:', error);
    }
  };

  return (
    <div className="claim-section">
      <Form onSubmit={handleSubmit}>
        <h3>Claim Tree</h3>
        <Form.Group controlId="code">
          <Form.Label>Enter 5-Digit Code:</Form.Label>
          <Form.Control
            type="text"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              setShowCodeAlert(false); // Hide the code validation alert when the user starts typing
            }}
          />
          {showCodeAlert && (
            <Alert variant="danger" className="mt-2">
              Please enter a valid 5-digit code.
            </Alert>
          )}
        </Form.Group>
        <Form.Text className="text-muted">
          Enter code 'CODEX' & your email to enter to win a free #NFTree.
        </Form.Text>
        <Form.Group controlId="email">
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            type="email"
            placeholder="example@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setShowEmailAlert(false); // Hide the email validation alert when the user starts typing
            }}
          />
          <Form.Text className="text-muted">
            This email is associated with claimed trees. To view them, please create a Carbonaut account.
          </Form.Text>
          {showEmailAlert && (
            <Alert variant="danger" className="mt-2">
              Please enter a valid email address.
            </Alert>
          )}
        </Form.Group>
        <div className="claim-submit">
          <ButtonTemplate type="submit">Submit</ButtonTemplate>
        </div>
        {claimSuccess && (
          <Alert variant="success" className="mt-3">
            Code claimed successfully!
          </Alert>
        )}
        {claimSuccess === false && (
          <Alert variant="danger" className="mt-3">
            This code has already been claimed or doesn't exist.
          </Alert>
        )}
      </Form>
    </div>
  );
};

export default ClaimTree;
