import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../components/button-template/ButtonTemplate";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { db } from "../configs/Firebase";
import { collection, query, where, getDocs } from "@firebase/firestore";
import AnimatedNumbers from "react-animated-numbers";
import ProjectionMap from "../components/projection-map/ProjectionMap";

const Home = () => {
    const { loginWithRedirect, isAuthenticated, user } = useAuth0();
    const changePage = useNavigate();

    // Total number of currently added trees on the platform
    const [treeCount, setTreeCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const totalTrees = await getTotalTrees();
            setTreeCount(totalTrees);
        };
    
        fetchData();
    }, []);
    
    // Not scalable, to be used temporarily
    const getTotalTrees = async () => {
        let totalTrees = 0;
        const q = query(
            collection(db, "users"),
            where("totalTrees", ">", 0)
        );
        const querySnapshot = await getDocs(q);
    
        querySnapshot.forEach((doc) => {
            totalTrees += doc.data().totalTrees;
        });
    
        return totalTrees;
    };

    return (
        <>
            <section>
                <div className="intro-header">
                    <div className="intro-content">
                        <div className="intro-text">
                            <div className="intro-heading">
                                Become a Carbonaut
                            </div>
                            <div className="intro-paragraph">
                                Our decentralized exchange (CODEX) tokenizes
                                carbon offsets, disrupting the voluntary carbon
                                offset market for individuals and businesses.
                            </div>
                        </div>
                        {isAuthenticated ? (
                            <Button
                                onClick={() => changePage("/dashboard")}
                                className="intro-button"
                            >
                                Dashboard
                            </Button>
                        ) : (
                            <Button
                                href="/"
                                onClick={() => loginWithRedirect()}
                                className="intro-button"
                            >
                                Get Started
                            </Button>
                        )}
                    </div>
                </div>

                <div className="container">
                    <div className="moto-wrap">
                        <div className="label">Why become a carbonaut</div>
                        <div className="moto-heading">
                            Join CODEX as a carbonaut to reduce your carbon
                            footprint and transform the voluntary carbon offset
                            market with tokenized carbon offsets.
                            <br />
                        </div>
                        <div className="divider"></div>
                    </div>

                    <div className="moto-wrap">
                        <div className="label">Current & Projected Growth</div>
                        <div className="moto-heading">
                            The graph indicates a positive impact in combatting
                            climate change, as sequestered carbon from
                            registered trees on the platform steadily increases.
                            Projected growth is promising, with potential for
                            acceleration as more people participate in planting
                            and caring for trees.
                        </div>
                    </div>

                    <div className="projection-section">
                        <div className="calculated-data">
                            <span>Total Registered NFTrees:</span>
                            <AnimatedNumbers
                                includeComma
                                animateToNumber={treeCount}
                                fontStyle={{ fontSize: 52 }}
                                configs={[
                                    {
                                        mass: 1,
                                        tension: 130,
                                        friction: 50,
                                    },
                                ]}
                            ></AnimatedNumbers>
                            <span>Average Total Carbon Sequestered:</span>
                            <div className="sequestered-data">
                                <AnimatedNumbers
                                    includeComma
                                    animateToNumber={treeCount * 250}
                                    fontStyle={{ fontSize: 52 }}
                                    configs={[
                                        {
                                            mass: 1,
                                            tension: 130,
                                            friction: 50,
                                        },
                                    ]}
                                ></AnimatedNumbers>
                                <span className="lbs">/lbs</span>
                            </div>
                        </div>

                        <ProjectionMap totalTrees={treeCount}></ProjectionMap>
                    </div>

                    <div className="divider"></div>
                </div>
            </section>
        </>
    );
};

export default Home;
