import { Web3Button } from "@web3modal/react";
import { watchAccount } from "@wagmi/core";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { useAccount, configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";

const chains = [arbitrum, mainnet, polygon];
const projectId = "a20b9e56d5fddaa4229ccf1eae5754d1";

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const WalletButton = ({ setState }) => {
  const walletAccount = useAccount();
  if (walletAccount.isConnected) {
    setState(walletAccount.address)
  }
  const walletWatcher = watchAccount((account) => {
    if (account.isConnected) {
      setState(account.address);
    } else {
      setState(null);
    }
  });
  return (
    <WagmiConfig client={wagmiClient}>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        className="web3-modal"
      />
      <Web3Button />
    </WagmiConfig>
  );
};

export default WalletButton;
