import { useState, createContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { doc, onSnapshot } from "@firebase/firestore";
import { db } from "../configs/Firebase";
import { createUser } from "../operations/CreateUser";
import Loading from "../components/Loading.js";
import EmailAlert from "../components/EmailAlert";
import Profile from "../components/profile-section/Profile";
import TreePanel from "../components/tree-panel/TreePanel";
import "./Dashboard.css";
import TreeMap from "../components/tree-map/TreeMap";
import { importClaimedTrees } from "../operations/ImportClaimedTrees";

export const UserContext = createContext();
export const AuthContext = createContext();

/**
 * This page is the main user Dashboard a user will land on
 * once authenticated. This page contains user profile details
 * and the tree information, number of trees & option to add
 * additional trees. This component uses the context to fetch
 * user information set within the contect.
 * @returns {HTMLElement}
 */
const Dashboard = () => {
  /**
   * These variable contain information about the current user,
   * that is authenticated, with specific information coming
   * from auth0 and Firebase.
   */
  const { user, isAuthenticated } = useAuth0();
  const [currentUser, setCurrentUser] = useState(null);

  /**
   * This method fetches user information from Firestore using
   * the user.sub id from auth0. This allows it to target a
   * specific document, and using the onSnapshot allows us to
   * update the data in the UI realtime, as the data changes.
   *
   * We also set the returned data to the currentUser variable.
   */
  const getUserData = async () => {
    const documentReference = doc(db, "users", user.sub);
    onSnapshot(documentReference, (doc) => {
      if (doc.exists()) {
        // console.log("Document data:", doc.data());
        setCurrentUser(doc.data());
      } else {
        // doc.data() will be undefined in this case
        createUser(
          user.sub,
          user.name,
          user.email,
          user.email_verified,
          user.picture
        );
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      importClaimedTrees(user);
    }
  }, [])

  if (!currentUser) {
    getUserData();
    return <Loading />;
  }
  
  return (
    <UserContext.Provider value={currentUser}>
      <AuthContext.Provider value={user}>
        {!user.email_verified && <EmailAlert />}

        <div className="dashboard">
          <div className="profile-panel">
            <Profile />
          </div>
          <div className="tree-panel">
            <TreePanel />
          </div>
          <div className="map-panel">
            <TreeMap />
          </div>
        </div>
      </AuthContext.Provider>
    </UserContext.Provider>
  );
};

/**
 * This route is protected using withAuthenticationRequired method
 * from auth0, where user needs to be logged in to access this route.
 */
export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <Loading />,
});
