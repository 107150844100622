import exifr from "exifr";

export function ExifExtractor(setCurrentCords) {
    const file = document.querySelector("input[type=file]").files[0];
    exifr.parse(file).then((output) => {
        if (output.latitude || output.longitude) {
            const gpsStr = output.latitude + ", " + output.longitude;
            setCurrentCords(gpsStr);
        }
    });
}
