import React from "react";
import './SectionTemplate.css'

const SectionTemplate = ({ children, title }) => {
  return (
    <div>
      <h1 className="section-heading">{title}</h1>
      <hr />
      {children}
    </div>
  );
};

export default SectionTemplate;
