import { React, useState } from 'react';
import { Alert } from 'react-bootstrap'
import './EmailAlert.css'

const EmailAlert = () => {
    const [show, setShow] = useState(true);
    // if (isAuthenticated && user.email_verified === false) {}
    if (show) {
        return (
            <Alert className='email-alert' variant="danger" onClose={() => setShow(false)} transition dismissible>
            <Alert.Heading className='email-head'>Oh snap! You haven't verified your email yet!</Alert.Heading>
            <p>
                Check your inbox to verify it's you. No email from Carbon Codex? Review your login info and try again.
            </p>
            </Alert>
        );
    }
    return <></>;
}

export default EmailAlert
