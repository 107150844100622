import React from "react";
import './Loading.css';

/**
 * This is a basic component with a loading
 * spinner animation.
 * @returns {HTMLElement}
 */
const Loading = () => (
  <div className="spinner">
    <span className="loader"></span>
  </div>
);

export default Loading;