import { getDoc, doc, setDoc } from "@firebase/firestore";
import { db } from '../configs/Firebase';

export const codeGenerator = async (treeInformation) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const codeLength = 5;

  const generateCode = () => {
    let code = '';
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    return code;
  };

  let newCode;
  let isUnique = false;

  while (!isUnique) {
    newCode = generateCode();
    try {
      // Attempt to get the document from Firestore
      const docRef = doc(db, "treeCodes", newCode);
      const docSnap = await getDoc(docRef);

      // Check if the document exists
      if (!docSnap.exists()) {
        // If it doesn't exist, set the document
        await setDoc(docRef, treeInformation);
        isUnique = true;
      }
    } catch (error) {
      // Handle Firestore read or write error
    }
  }

  return newCode;
};