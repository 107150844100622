import { useContext, useState, useEffect, useRef } from 'react'
import { Map, Marker } from "pigeon-maps"
import './TreeMap.css'
import { UserContext } from '../../pages/Dashboard'
import Globe from 'react-globe.gl';
import SectionTemplate from '../section-template/SectionTemplate';

const TreeMap = () => {
    const globeReference = useRef();
    const userData = useContext(UserContext);

    // let GPSData = [];
    // userData.nftreeInfo.forEach(tree => {
    //     GPSData.push(tree.coordinates.replace(/[`()\{\}\[\]\\\/]/gi, ''));
    // });

    let GPSData = [];
    userData.nftreeInfo.forEach(tree => {
        const removeChars = tree.coordinates.replace(/[`()\{\}\[\]\\\/ ]/gi, '');
        const processedCords = removeChars.split(",");
        GPSData.push({
          "lat": processedCords[0],
          "lng": processedCords[1],
          "pop": 2000000
        })
    });

    useEffect(() => {
        // Auto-rotate
        globeReference.current.controls().autoRotate = true;
        globeReference.current.controls().autoRotateSpeed = 0.3;
    }, []);

    return <SectionTemplate title='Tree Map'>
        <div className="map-box">
            {/* <Map  height={600} defaultCenter={[40, -101]} defaultZoom={5}>
                {GPSData.map((tree) => 
                    <Marker width={50} anchor={[Number(tree.split(',')[0]), Number(tree.split(',')[1])]} key={tree.id}/>
                )}
            </Map> */}
            <Globe
                ref={globeReference}
                globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                
                hexBinPointsData={GPSData}
                hexBinPointWeight="pop"
                hexAltitude={d => d.sumWeight * 6e-8}
                hexBinResolution={4}
                hexTopColor={d => d.sumWeight * 100}
                hexSideColor={d => d.sumWeight * 100}
                hexBinMerge={true}
                enablePointerInteraction={false}

                width={window.screen.width / 1.08}
                height={window.screen.height / 1.75}
            />
        </div>
    </SectionTemplate>
}

export default TreeMap