import React from "react";
import { TreeForm } from "../forms/TreeForm";
import { v4 as uuidv4 } from "uuid";
import NftreeList from "../tree-list/NftreeList";
import "./TreePanel.css";
import SectionTemplate from "../section-template/SectionTemplate";

const TreePanel = () => {
  return (
    <SectionTemplate title='Your Trees'>
      <TreeForm
        formName="Add A NFTree"
        buttonName="ADD TREE"
        UUID={uuidv4()}
      />
      <NftreeList />
    </SectionTemplate>
  );
};

export default TreePanel;
