import { doc, updateDoc, arrayRemove, setDoc, increment } from "@firebase/firestore";
import { db } from '../configs/Firebase'

export async function deleteTree(AUTHID, tree) {
    const userRef = doc(db, "users", AUTHID);

    if (!tree.paymentInfo.paid) {
        await updateDoc(userRef, { nftreeInfo: arrayRemove(tree) })
        await setDoc(userRef, { totalTrees: increment(-1) }, { merge: true });
        // window.location.reload();
    }
}