import { doc, updateDoc } from "firebase/firestore";
import { db } from '../configs/Firebase';

export async function updateUser(AuthID, data) {
  const userRef = doc(db, "users", AuthID);
  try {
    await updateDoc(userRef, {
      "userDetails.name": data.name,
      "userDetails.email": data.email,
      "userDetails.walletAddress": data.walletAddress,
    });
    console.log("User data updated successfully");
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
}
