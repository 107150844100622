import React, { useContext } from "react";
import { Card, Button, Popover, OverlayTrigger, Spinner } from "react-bootstrap";
import "./NftreeList.css";
import { deleteTree } from "../../operations/DeleteTree";
import { AuthContext } from "../../pages/Dashboard";
import { TreeForm } from "../forms/TreeForm";
import { UserContext } from "../../pages/Dashboard";
import ButtonTemplate from "../button-template/ButtonTemplate";

function VerificationStatus({ status }) {
  if (status === "approved") {
    return (
      <Button variant="success" className="approval" disabled>
        Verified Nftree
      </Button>
    );
  } else if (status === "rejected") {
    return (
      <>
        <Button variant="danger" className="rejection">
          Nftree Rejected<b> X </b>
        </Button>
        <p className="verification-text">Please check your information!</p>
      </>
    );
  }

  return <></>;
}

const NftreeList = () => {
  const currentUser = useContext(UserContext);
  const { sub } = useContext(AuthContext);

  const confirm = (tree) => {
    deleteTree(sub, tree);
  };

  const submit = (tree) => (
    <Popover id="popover-basic" className="delete-popover">
      <Popover.Header as="h3" className="delete-popover-heading">
        Confirm Deletion
      </Popover.Header>
      <Popover.Body>
        Make sure you have selected the right NFTree to delete. Deleted data
        will be lost permanently.
        <br />
        <br />
        <Button
          variant="danger"
          className="nftree-delete-button-popover"
          onClick={() => confirm(tree)}
        >
          Delete
        </Button>
      </Popover.Body>
    </Popover>
  );

  const NftreeUser = () => {
    if (currentUser && currentUser.totalTrees >= 1) {
      return (
        <>
          {currentUser.nftreeInfo.map((tree) => {
            if (tree.wasClaimed == undefined) {
              tree.wasClaimed = false;
            }
            return (
            <div className="nftreeGrid" key={tree.id}>
              <Card style={{ width: "20rem" }} className="nftreeCard">
                {tree.wasClaimed && (
                  <div className="claimedTree">
                    <h4>Claimed Tree</h4>
                    <h4>{tree.claimID}</h4>
                  </div>
                )}
                <div>
                  <a href={`https://carbonaut.app/${sub}/${tree.id}`}>
                    <img
                      src={`https://chart.googleapis.com/chart?cht=qr&chs=177x177&chl=https://carbonaut.app/${sub}/${tree.id}`}
                      alt="qr"
                      className="qrcode"
                    />
                  </a>
                </div>
                <Card.Body>
                  <Card.Title>
                    <b>{tree.name}</b>
                  </Card.Title>
                  <div>
                    <ul>
                      <li>
                        <b>Species:</b> {tree.species}
                      </li>
                      <li>
                        <b>Maturity:</b> {tree.maturity}
                      </li>
                      <li>
                        <b>Height:</b> {tree.height}
                      </li>
                      <li>
                        <b>Diameter:</b> {tree.diameter}
                      </li>
                      <li>
                        <b>Coordinates:</b> {tree.coordinates}
                      </li>
                      <li>
                        <b>OpenSeaLink:</b> <a href={tree.openSeaLink}>{tree.openSeaLink}</a>
                      </li>
                      <li>
                        <b>Health:</b> {tree.health}
                      </li>
                    </ul>
                  </div>
                  {!tree.paymentInfo.paid && (
                    <>
                      <div className="nftreeCard-buttons">
                        <TreeForm
                          formName="Edit NFTree"
                          buttonName="Edit"
                          UUID={tree.id}
                          type="edit"
                          currentTree={tree}
                        />
                        {!tree.wasClaimed && (
                        <OverlayTrigger
                          trigger="click"
                          placement="top-end"
                          overlay={submit(tree)}
                        >
                          <Button
                            variant="danger"
                            className="nftree-delete-button"
                          >
                            X
                          </Button>
                        </OverlayTrigger>
                        )}
                      </div>
                      {!tree.wasClaimed && (
                      <Button
                        variant="dark"
                        className="purchase-button"
                        disabled
                      >
                        Purchase Unavailable
                      </Button>
                      )}
                    </>
                  )}
                  {!tree.wasClaimed && tree.isVerified === "unknown" ? (
                    <>
                      <Button variant="dark" className="purchase-button" disabled>
                        Verifying Tree
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="tree-verify-spinner"
                        />
                      </Button>
                      <p className="verification-text">Verification may take several days.</p>
                    </>
                  ) : (
                    <VerificationStatus status={tree.isVerified} currentUser={currentUser} currentTree={tree} tree={tree} />
                  )}
                </Card.Body>
              </Card>
            </div>
          )})}
        </>
      );
    } else {
      return (
        <Card style={{ width: "20rem" }} className="nftreeCard">
          <div>
            <a href={`https://carbonaut.app/test/test`}>
              <img
                src={`https://chart.googleapis.com/chart?cht=qr&chs=177x177&chl=https://carbonaut.app/test/test`}
                alt="qr"
                className="qrcode"
              />
            </a>
          </div>
          <Card.Body>
            <Card.Title>
              <b>Sample Nftree</b>
            </Card.Title>
            <div>
              <ul>
                <li>
                  <b>Species:</b> Black Ash
                </li>
                <li>
                  <b>Maturity:</b> Sapling
                </li>
                <li>
                  <b>Height:</b> 225cm
                </li>
                <li>
                  <b>Diameter:</b> 92cm
                </li>
                <li>
                  <b>Coordinates:</b> 41.7047, -80.3337
                </li>
                <li>
                  <b>OpenSeaLink:</b> <a href="https://www.opensea.io"> https://www.opensea.io </a>
                </li>
                <li>
                  <b>Health:</b> Average
                </li>
              </ul>
            </div>
            <div className="nftreeCard-buttons">
              <ButtonTemplate>Edit NFTree</ButtonTemplate>
              <Button
                variant="danger"
                className="nftree-delete-button"
              >
                X
              </Button>
            </div>
            <Button
              variant="dark"
              className="purchase-button"
              disabled
            >
              Purchase Unavailable
            </Button>
          </Card.Body>
        </Card>
      )
    }
  };

  return (
    <div className="nftree-container">
      <NftreeUser />
    </div>
  );
};

export default NftreeList;
