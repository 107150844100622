import { useEffect, useState } from 'react';
import Button from '../components/button-template/ButtonTemplate';
import { db } from '../configs/Firebase';
import { doc, setDoc, collection, query, where, limit, getDocs } from "@firebase/firestore";
import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import './Admin.css';

const Admin = () => {
    const { user } = useAuth0();
    /**
     * *** NOT SCALABLE, REQUIRES PAGINATION / CALL LIMIT AS THE USER BASE GROWS ***
     * Holds an object containing all the tree currently stored in the database.
     */
    const [unverifiedTreeList, setUnverifiedTreeList] = useState(null);
    /**
     * Holds an object containing the tree UUID with an array of all images uploaded for that tree
     */
    const [imageLinks, setImageLinks] = useState(null);
    const changePage = useNavigate();

    /**
     * Check if the current user is anything other than the users below otherwise 
     * they get redirected to the dashboard.
     * 
     * If the user is not logged in they will be redirected to the login screen.
     */
    if (user.email != "gtazia123@gmail.com" && user.email != "alex@cascadiacarbon.com") {
        changePage('/dashboard');
    }

    /**
     * Fetch a limited number of trees available from the database, with image links
     */
    const fetchAllTrees = async () => {
        const documentsReference = collection(db, "users");
        const dataQuery = query(documentsReference, where("totalTrees", ">", 0));
        const querySnapshot = await getDocs(dataQuery);
        const storage = getStorage();

        /**
         * We fetch the links of uploaded images of a tree.
         * 
         * Iterate over the list of the trees within that account, then iterate
         * over all the trees to find out if they have images uploaded, if so, 
         * then we fetch the links for them to be displayed alongside the tree
         * data.
         */
        querySnapshot.docs.map(doc => {
            doc.data().nftreeInfo.forEach((tree) => {
                const UUID = tree.id;
                const treeReference = ref(storage, `${UUID}/`);
                let imageLinksArray = [];
                
                listAll(treeReference)
                .then((response) => {
                    response.items.forEach((itemRef) => {
                        const storageRef = ref(storage, `${UUID}/${itemRef.name}`);

                        // Method to get download URLs from firebase storage
                        getDownloadURL(storageRef)
                        .then((url) => {
                            imageLinksArray.push(url);
                        }).catch((error) => {
                            console.error(error);
                        });
                    })
                }).then(() => {
                    setImageLinks(prevKeyMap => ({ ...prevKeyMap, [UUID]: imageLinksArray }));
                }).catch((error) => {
                    console.error(error);
                });
            })
        })

        /** 
         * Temporary solution, wait for the links to be fetched then set the
         * data to the tree list variable.
         */
        setTimeout(() => {
            // set the fetched documents mapped as an object
            setUnverifiedTreeList(querySnapshot.docs.map(doc => (
                {
                    id: doc.id,
                    data: doc.data()
                }
            )));
        }, 2000);
    };

    /**
     * This functions assigns the verification status to that specific tree
     * and updates it within the database instantly.
     * 
     * @param {Object} currentUser 
     * @param {Object} tree 
     * @param {String} status 
     */
    const setVerificationStatus = async (currentUser, tree, status) => {
        const userDocumentReference = doc(db, "users", currentUser.id);
        const searchIndex = currentUser.data.nftreeInfo.findIndex((value) => value.id === tree.id);

        currentUser.data.nftreeInfo[searchIndex].isVerified = status;
        await setDoc(userDocumentReference, currentUser.data, { merge: true })
        .then(() => {
            fetchAllTrees();
        })
    }

    useEffect(() => {
        fetchAllTrees();
    }, [])

    return (
        <div id='admin-panel'>
            <div className='admin-heading'>
                <h1>NFTree Verification</h1>
            </div>
            <hr />
            {unverifiedTreeList ? (<div>
                <h5>Unverified Trees</h5>
                <hr />
                {unverifiedTreeList.map((currentUser) => (
                    <div key={currentUser.data.userDetails.email}>
                        {currentUser.data.nftreeInfo.map((tree) => (
                            <div key={tree.id}>
                                {console.log(tree.isVerified)}
                                {tree.isVerified === "unknown" && (<>
                                    <div className='admin-tree-block'>
                                        <ul className='admin-tree-details'>
                                            <li><b>Paid: </b>{tree.paymentInfo.paid ? <span>True</span> : <span>False</span>}</li>
                                            <li><b>Name: </b>{currentUser.data.userDetails.name}</li>
                                            <li><b>Email: </b>{currentUser.data.userDetails.email}</li>
                                            <li><b>Species: </b>{tree.species}</li>
                                            <li><b>Maturity: </b>{tree.maturity}</li>
                                            <li><b>Height: </b>{tree.height}</li>
                                            <li><b>Diameter: </b>{tree.diameter}</li>
                                            <li><b>Coordinates: </b>{tree.coordinates}</li>
                                            <li><b>Wallet Address: </b></li>
                                            {currentUser.data.userDetails.walletAddress}
                                        </ul>
                                        <div className='admin-image-container'>
                                            {imageLinks[tree.id].length > 0 ? imageLinks[tree.id].map((link) => (<img src={link} height="200" width="200"/>)) : <p><u><b>No Images</b></u></p>}
                                        </div>
                                        <div className='admin-button-container'>
                                            <Button onClick={() => {setVerificationStatus(currentUser, tree, "approved")}}>APPROVE</Button>
                                            <Button onClick={() => {setVerificationStatus(currentUser, tree, "rejected")}}>REJECT</Button>
                                        </div>
                                    </div>
                                    <hr/>
                                </>)}
                            </div>
                        ))}
                    </div>
                ))}
                <p className='admin-notree-text'><u><i>No More Trees To Verify, Please Check Back Later!</i></u></p>
                <hr />
                <h5>Verified Trees</h5>
                <hr />
                {unverifiedTreeList.map((currentUser) => (
                    <div key={currentUser.data.userDetails.email}>
                        {currentUser.data.nftreeInfo.map((tree) => (
                            <div key={tree.id}>
                                {tree.isVerified === "approved" && (<>
                                    <div className='admin-tree-block'>
                                        <ul className='admin-tree-details'>
                                            <li><b>Paid: </b>{tree.paymentInfo.paid ? <span>True</span> : <span>False</span>}</li>
                                            <li><b>Name: </b>{currentUser.data.userDetails.name}</li>
                                            <li><b>Email: </b>{currentUser.data.userDetails.email}</li>
                                            <li><b>Species: </b>{tree.species}</li>
                                            <li><b>Maturity: </b>{tree.maturity}</li>
                                            <li><b>Height: </b>{tree.height}</li>
                                            <li><b>Diameter: </b>{tree.diameter}</li>
                                            <li><b>Coordinates: </b>{tree.coordinates}</li>
                                            <li><b>Wallet Address: </b>{currentUser.data.userDetails.walletAddress}</li>
                                        </ul>
                                    </div>
                                    <hr/>
                                </>)}
                            </div>
                        ))}
                    </div>
                ))}
            </div>)
            :
            <Loading />}
        </div>
    )
}

/**
 * This route is protected using withAuthenticationRequired method
 * from auth0, where user needs to be logged in to access this route.
 */
 export default withAuthenticationRequired(Admin, {
    onRedirecting: () => <Loading />,
  });